import { useThemeStorage } from "@/lib/theme/usetheme";
import { SiteSettingsProfile } from "@prisma/client";
import { createContext, useContext, useEffect, useState } from "react";

interface GlobalValues {
  setTheme: (newVal: string) => void;
  toggleTheme: () => void;
  theme: string | undefined;
  loading?: boolean;
  setLoading: (val: boolean, matter?: string) => void;
  siteSettings?: SiteSettingsProfile | null;
  appVersion: string;
  enabledFeatures: Record<string, boolean>;
}

export const globalDefaultValues: GlobalValues = {
  setTheme: () => { },
  setLoading: () => { },
  toggleTheme: () => { },
  theme: "dark",
  appVersion: "1.0.0",
  enabledFeatures: {}
};

export const globalContext = createContext<GlobalValues>(globalDefaultValues);

export function GlobalProvider(props: {
  children?: any;
  siteSettings: SiteSettingsProfile | null;
  appVersion: string;
  enabledFeatures: {
    name: string;
    active: boolean;
    type: string | null;
    otherData: any
  }[]
}) {
  const { theme, setTheme, toggleTheme } = useThemeStorage();

  const [loading, _setLoading] = useState(true);
  const [loadingMatter, setLoadingMatter] = useState("");
  const setLoading = (bool: boolean, matter?: string) => {
    _setLoading(bool);
    setLoadingMatter(bool ? matter || "" : "");
  };

  const [enabledFeatures, setEnabledFeatures] = useState<GlobalValues["enabledFeatures"]>({})

  const values: GlobalValues = {
    setTheme,
    toggleTheme,
    theme,
    siteSettings: props.siteSettings,
    loading,
    setLoading,
    appVersion: props.appVersion,
    enabledFeatures
  };



  useEffect(() => {
    setLoading(true, "loading page")


    const features: GlobalValues["enabledFeatures"] = {};
    for (const feature of props.enabledFeatures) {
      features[feature.name] = true
    }

    setEnabledFeatures(features)


    const setAppHeight = (cb?: any) => {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight - 1}px`
      );
      if (typeof cb == "function") {
        cb();
      }
    };

    window.addEventListener("resize", setAppHeight);
    setAppHeight(() => setLoading(false));

    return () => {
      window.removeEventListener("resize", setAppHeight);
    };
  }, []);

  useEffect(() => {
    if (!loading) return;

    const timer = setTimeout(
      () => {
        // if (loadingMatter.length)
        //   toast.error(
        //     "Loading timed out " +
        //     (loadingMatter?.length ? "with " + loadingMatter : "")
        //   );
        setLoading(false);
      },
      loadingMatter.length ? 10 * 1000 : 1000
    );

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("dark", theme == "dark");
    document
      .querySelector("html")
      ?.setAttribute(
        "data-theme",
        theme || (globalDefaultValues.theme as string)
      );
  }, [theme]);

  return (
    <globalContext.Provider value={values}>
      {props.children}
      {/* <LoadingOverpage loading={loading}>
        {loadingMatter.length ? loadingMatter : "Loading content"}
      </LoadingOverpage> */}
    </globalContext.Provider>
  );
}

export default function useGlobal() {
  return useContext(globalContext);
}
