import fetcher from "@/lib/client/fetcher";

const handleOnError = (error: string) => {
  throw new Error(`Error: ${error}`);
};

export default function swrConfig() {
  return {
    fetcher,
    onError: handleOnError,
    refreshInterval: 1000,
  };
}
