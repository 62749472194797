import { Notification } from "@prisma/client";
import { createContext } from "react";

export interface NotificationsType {
  notifications: Notification[];
  markAsSeen: (id: string) => Promise<void>;
  markAsNotified: (id: string) => Promise<void>;
  deleteItem: (id: string) => Promise<void>;

}

const defaultValues: NotificationsType = {
  notifications: [],
  markAsSeen: async (id: string) => { },
  markAsNotified: async (id: string) => { },
  deleteItem: async (id: string) => { },
};

export const NotificationsContext =
  createContext<NotificationsType>(defaultValues);
