export default async function api(
  url: string,
  options: Omit<Partial<RequestInit>, "body"> & { body?: any; stream?: boolean }
) {
  const controller: AbortController = options.signal
    ? {
        signal: options.signal,
        abort() {
          options.signal?.dispatchEvent(new Event("abort"));
        },
      }
    : new AbortController();

  const { body, headers, ...opts } = options;
  let requestBody = "{}";
  try {
    requestBody = JSON.stringify(body);
    // eslint-disable-next-line no-empty
  } catch {}

  const response = await fetch(url, {
    body: requestBody,
    headers: {
      "Content-Type": "application/json",
      Connection: options.stream ? "keep-alive" : "close",
      ...headers,
    },
    signal: controller.signal,
    ...opts,
  });

  let result: any = {};

  if (!response.ok) {
    result = await response.text();
    try {
      result = JSON.parse(result);
    } catch {
      console.log("response data:", result);
    }
    throw new Error(result.detail);
  }

  if (!options.stream) {
    result = await response.text();
    try {
      result = JSON.parse(result);
    } catch {
      console.log("response data:", result);
    }
  }

  if (options.stream) return [response.body, controller];

  return { status: response.status, ...result, url };
}
