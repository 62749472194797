"use client";
import { useContext, useEffect, useMemo, useCallback } from "react";
import { NotificationsContext, NotificationsType } from "./context";
import { Notification } from "@prisma/client";
import { useFeed } from "@/components/feed";
import { toast } from "react-hot-toast";
import { NotificationItem } from "./item";
import { useSession } from "next-auth/react";

export function NotificationsProvider(props: { children: any }) {
  const session = useSession();
  const querier = useFeed<Notification>({
    src: "/v2/notifications",
    periodically: true,
    // additionalQuery: {
    //   seen: false,
    // },
    customUseQueryOptions: {
      enabled: session.status == "authenticated",
      cacheTime: 5000,
      refetchInterval: 60 * 60 * 1000,
    },
  });

  const unNotedNotifications =
    querier.data?.data.filter((item) => !item.notified) || [];

  const unseenNotifications =
    querier.data?.data.filter((item) => !item.seen) || [];

  const markAsSeen = useCallback(async (id: string) => {
    await querier.mutateAsync(id, { seen: true });
  }, []);

  const deleteItem = useCallback(async (id: string) => {
    await querier.mutateAsync(id, undefined, "DELETE");
  }, []);


  const markAsNotified = useCallback(
    async (id: string) => {
      await querier.mutateAsync(id, { notified: true });

      const item = (querier.data?.data || []).find((item) => item.id == id);

      if (item) {
        toast.custom(NotificationItem(item), {
          position: "bottom-right",
        });
      }
    },
    [querier]
  );

  const values: NotificationsType = useMemo(
    () => ({
      notifications: querier.data?.data || [],
      markAsSeen,
      markAsNotified,
      deleteItem
    }),
    [querier.data?.data, markAsSeen, markAsNotified]
  );

  useEffect(() => {
    if (typeof window == undefined) return;
    (async () => {
      for (const item of unNotedNotifications) {
        values.markAsNotified(item.id);

        // try {
        //   if ("Notification" in window) {
        //     const title = `${item.senderName} ${item.action} ${item.type}`;
        //     const body = JSON.stringify(item.context, null, 2);
        //     const notification = new window.Notification(title, {
        //       body,
        //       data: getNotificationItemLink(item),
        //     });
        //     notification.onclick = function (e) {
        //       // @ts-ignore
        //       window.location.href = e.target?.data || "";
        //     };
        //   }
        // } catch {
        //   console.log("could't notify you");
        // }
      }
    })();
  }, [JSON.stringify(unNotedNotifications)]);

  return (
    <NotificationsContext.Provider value={values}>
      {props.children}
    </NotificationsContext.Provider>
  );
}
export default function useNotifications() {
  return useContext(NotificationsContext);
}

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export async function subscribeToBrowserNotifications() {
  if (!("serviceWorker" in navigator)) return;

  const pers = await window.Notification.requestPermission();
  console.log({ pers });
  if (pers == "granted") {
    console.log(
      await navigator.serviceWorker.register("/workers/notifications.js", {
        scope: "/",
      })
    );
  }

  const registration = await navigator.serviceWorker.ready;
  const key = process.env.NEXT_PUBLIC_VAPID_KEY;

  if (!key) return console.warn("VOVAPID Not Setup");

  const sub = await registration.pushManager.getSubscription();

  // Subscribe to push notifications
  const subscription =
    sub ||
    (await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(key),
    }));

  await fetch("/api/v2/notifications/subscription", {
    method: "POST",
    body: JSON.stringify(subscription),
    headers: {
      "content-type": "application/json",
    },
  });
}
