import JSON5 from 'json5'


export function trimBy<T>(arr: T[], attribute: keyof T): T[] {
  const obj: any = {};

  arr.forEach((e) => {
    obj[e[attribute] ? e[attribute] : crypto.randomUUID()] = e;
  });

  return Object.values(obj);
}

export function convertToPlainObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function removeDatesFromObject<T>(obj: T): T {
  if (obj instanceof Date) {
    return obj.getTime() as any;
  } else if (Array.isArray(obj)) {
    return obj.map(removeDatesFromObject) as any;
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((acc: any, [key, val]) => {
      if (!acc) acc = {};
      acc[key] = removeDatesFromObject(val);
      return acc;
    }, {});
  } else {
    return obj;
  }
}

export const k = (setter: any) => (e: any) => setter(e.target.value);

export const formatSize = (size: number) => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(0)} KB`;
  if (size < 1024 * 1024 * 1024) return `${(size / 1024 / 1024).toFixed(0)} MB`;
  return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
};


export const download = (name: string, data: string | Uint8Array, options?: { extension?: string, mimeType?: string }): Promise<void> => {
  return new Promise(async (resolve) => {
    try {
      const streamSaver = (await import("streamsaver")).default;
      const fileStream = streamSaver.createWriteStream(name + (options?.extension || ".txt"));
      const writer = fileStream.getWriter();
      const encoder = new TextEncoder();
      const uint8Array = (data instanceof Uint8Array) ? data : encoder.encode(data as string);

      writer.write(uint8Array);
      writer.close();

      // Small delay to ensure download starts before resolving
      setTimeout(resolve, 100);
    } catch (e) {
      console.error("fallback", e);
      // Fallback for unsupported browsers
      const blob = new Blob([data], { type: options?.mimeType || 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name + (options?.extension || ".txt");
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      resolve();
    }
  });
}


export const jsonDownload = (
  name: string,
  data: object,
  options?: { extension?: string; noFormat?: boolean }
) => {
  return download(name, JSON.stringify(data, null, options?.noFormat ? undefined : 2), {
    extension: options?.extension || ".json"
  })
};



export const upload = async (files: File[]) => {
  return await Promise.all(
    files.map((file) => {
      return new Promise((s, r) => {
        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = (event) => {
          const content = event.target?.result || "";
          s(content); // do something with the JSON data
        };
      });
    })
  );
};

export const JsonUpload = async (files: File[]) => {
  return await upload(files).then(fes => fes.map(f => JSON5.parse((f || "{}") as any)));
};

export const firstletterUppercase = (txt?: string) => {
  if (!txt) return "";
  return txt[0].toUpperCase() + txt.substring(1);
};
