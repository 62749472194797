import { Notification } from "@prisma/client";
import clsx from "clsx";
import moment from "moment";
import Link from "next/link";
import toast, { Toast } from "react-hot-toast";

export const NotificationItem = (item: Notification) =>
  function F123(
    t: Partial<Toast> & {
      noMaxWidth?: boolean;
      noCloseButton?: boolean;
      children?: any;
    }
  ) {
    const link = getNotificationItemLink(item);

    return (
      <div
        className={clsx(
          `w-full rounded-lg bg-white shadow-lg ring-1 ring-black/5 dark:bg-gray-300/25`,
          {
            "max-w-md": !t.noMaxWidth,
          }
        )}
      >
        {t.children && <div className="p-4">{t.children}</div>}
        <div className={clsx(`pointer-events-auto flex w-full`)}>
          <div className="w-0 flex-1 p-4">
            <Link href={`/dashboard/notifications/${item.id}`}>
              <div className="flex items-start">
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium">
                    <span className="font-semibold">{item.senderName}</span>{" "}
                    {item.action} {item.type}{" "}
                    <span className="text-xs">
                      {moment(item.createdAt).fromNow()}
                    </span>
                  </p>
                  <p className="mt-1 text-clip text-sm text-gray-500">
                    {((item.context || {}) as any)?.message}
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex items-center border-l border-gray-200">
            {link && (
              <Link href={link}>
                <button
                  onClick={async () => {
                    toast.dismiss(t.id);
                  }}
                  className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:text-indigo-200 dark:hover:text-indigo-200"
                >
                  Open
                </button>
              </Link>
            )}
            {!t.noCloseButton && (
              <button
                onClick={async () => {
                  toast.dismiss(t.id);
                }}
                className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-red-400 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

export const getNotificationItemLink = (item: Notification) => {
  const context = item.context as any;
  switch (item.type) {
    case "review":
      return `/dashboard/admin/users/${item.senderId}/apps/chat/${context?.conversationId}?messageIndex=${context?.messageIndex}`;
    case "your message":
      return `/workspace/${context?.workspaceId}/chat/${context?.conversationId}?messageIndex=${context?.messageIndex}`;
    default:
      break;
  }
};

export const getNotificationItemTitle = (item: Notification) => {
  return `${item.senderName} ${item.action} ${item.type}`;
};

export const getNotificationItemBody = (item: Notification) => {
  return JSON.stringify(item.context, null, 2);
};
